<template>
	<div>{{ErrorMessage}}</div>
</template>

<script>
	export default {
		data(){
			return{
				ErrorMessage:''
			}
		},
		created() {
			this.getErrormessage()
		},
		methods:{
			getErrormessage(){
				var URL = window.location.href;
				let h = URL.split("?")[1];
				let domain = h.split("=")[1];
				// decodeURIComponent
				this.ErrorMessage = decodeURIComponent(domain)
				console.log( domain ,'页面链接')
			}
		}
	}
</script>

<style>
</style>
